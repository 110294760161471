.quill-text-editor {
  .ql-container {
    min-height: 12em;
    border: none;

    &.ql-bubble {
      .ql-tooltip {
        border-radius: 5px;
      }
    }
  }
  &.edit-comment .ql-container {
    min-height: auto;
  }

  .ql-toolbar {
    button {
      .ql-stroke,
      .ql-fill,
      .ql-picker {
        stroke: #6b7280;
      }

      &.ql-active {
        .ql-stroke,
        .ql-fill,
        .ql-picker {
          stroke: #ef4444;
        }
      }

      &:hover {
        color: #ef4444;

        .ql-stroke,
        .ql-fill,
        .ql-picker {
          stroke: #ef4444;
        }
      }
    }
  }
  .ql-editor {
    overflow: hidden;
  }
}
