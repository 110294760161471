@import 'components/Datepicker';
@import 'components/tabnav';
@import 'layouts/components/submenu';
@import 'components/Editor/CommentEditor';
@import 'components/Editor/components/MailBlockTemplateEditor';
@import 'components/PaymentForm';

@import 'features/sales/Sales';

@import 'features/tasks/Tasks';
@import 'features/tasks/Comments';

@import 'features/clients/Client/Billing/components/ChangeBillingDatePicker.css';
@import 'features/clients/Client/Dashboard/Mockup/Mockup';
@import 'features/sales/Quote/preview';

body.profile-layout {
  .client-content {
    @apply col-span-3;
  }

  .client-updates {
    @apply col-span-2;
  }

  &.billing {
    .client-content {
      @apply col-span-2;
    }

    .client-updates {
      @apply col-span-3;
    }
  }

  &.no-sidebar {
    .client-content {
      @apply col-span-5;
    }

    .client-updates {
      display: none;
    }
  }
}

.bs-shadow {
  &:hover {
    box-shadow: 0px 20px 24px rgba(17, 17, 17, 0.06);
  }
}

.absolute-center {
  @apply absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2;
}

.add-range-bg {
  &::before,
  &::after {
    background: #d3e4f5;
    content: '';
    position: absolute;
    top: 0;
    width: 12.5px;
    height: 100%;
  }

  &::before {
    left: -12px;
  }
  &::after {
    right: -12px;
  }
}
