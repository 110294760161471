.quill-body-copy {
  .ql-editor {
    @apply border border-white rounded-xl bg-gray-50;
    color: #202020;

    &:hover {
      @apply border-gray-200;
    }

    &:focus {
      @apply bg-white border-gray-200;
    }
  }
}

.mockup-img-button {
  &:hover,
  &:focus {
    border: 1px solid rgb(228 121 17 / 50%);
    box-shadow: 0 0 3px 2px rgb(228 121 17 / 50%);
  }
}

.mockup-vid-play-btn {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 0 15px 20.6px;
  border-color: transparent transparent transparent #ed2e2e;
  display: block;
}
