#btPaymentForm {
  iframe {
    background: transparent;

    #credit-card-number {
      @apply border-white bg-gray-50 focus:ring-gray-200 focus:border-gray-200 hover:border-gray-200 block w-full sm:text-sm placeholder-gray-300 rounded-md;
    }
  }
}

.braintree-hosted-fields-focused {
  @apply text-sm;
  //@apply border-b-2 border-custom-blue;
  //border-bottom: 2px solid $blue;
  transition: all 10ms ease;
}

.braintree-hosted-fields-invalid {
  @apply border-b-2 border-custom-red;
  //border-bottom: 2px solid $red;
  transition: all 10ms ease;
}
