.add-comment-box {
  .gradient-box {
    background: linear-gradient(
      180deg,
      rgba(236, 236, 236, 0) 0%,
      #ececec 65.1%
    );
    width: 100%;
    height: 50px;
  }
}

.comment-container {
  .comment {
    h1 {
      @apply text-xl;
    }
    h2 {
      @apply text-lg;
    }
    h3 {
      @apply text-base;
    }
    a {
      @apply text-red-500 hover:text-red-700;
    }
    blockquote {
      @apply whitespace-pre-wrap border-l-2 pl-4;
    }

    blockquote:first-of-type {
      @apply mt-4;
    }
  }
  .log {
    blockquote {
      &::before {
        content: '\201C';
        font-size: 30px;
        line-height: 1;
      }
      &::after {
        content: '\201D';
        font-size: 30px;
        line-height: 1;
        bottom: -15px;
        position: relative;
        align-self: flex-end;
      }
    }
    a {
      @apply text-red-500 underline;
    }

    .error-msg {
      @apply text-red-600 italic;
    }
    .event-actor,
    .actor {
      @apply text-yellow-500;

      &.zoho {
        @apply text-red-500;
      }
    }
    .link {
      @apply text-green-500 underline;
      &.email {
        @apply text-purple-500;
      }
    }
    .filename,
    .changed,
    .changedTo {
      @apply text-gray-700;
    }
    .changed {
      &.status {
        @apply bg-gray-100 px-1.5 leading-tight rounded-sm capitalize;
        &.draft {
          @apply bg-gray-100 text-gray-700;
        }
        &.created,
        &.registered,
        &.open,
        &.published {
          @apply bg-yellow-100 text-yellow-700;
        }
        &.invited {
          @apply bg-blue-100 text-blue-700;
        }
        &.cancelled,
        &.overdue,
        &.marked.for.revision {
          @apply bg-red-100 text-red-700;
        }
        &.subscribed,
        &.live,
        &.paid,
        &.resolved,
        &.approved {
          @apply bg-green-100 text-green-700;
        }
        &.active {
          @apply bg-green-100 text-green-700 uppercase;
        }
        &.inactive,
        &.fail {
          @apply bg-red-100 text-red-700 uppercase;
        }
        &.void,
        &.writtenoff {
          @apply bg-purple-100 text-purple-700;
        }
      }
      &.action {
        @apply px-1.5 leading-tight rounded-sm uppercase text-yellow-500 bg-white;
      }
    }
    .changedTo {
      @apply italic;
      &.status {
        @apply bg-green-100 px-2 leading-tight rounded-md not-italic;
      }
    }
    .role {
      @apply capitalize text-green-600;
    }
    .survey-name {
      @apply capitalize text-blue-600;
    }
  }
}
