.add-select-line {
  &::after {
    content: '';
    @apply border-b-2 border-red-700 w-full absolute -bottom-1.5;
  }
}

.add-select-line-deals {
  &::after {
    content: '';
    @apply border-b-2 border-blue-700 w-full absolute -bottom-1.5;
  }
}

.list-group {
  .list-group-tab {
    @apply uppercase px-4 py-3 text-sm font-bold relative leading-none;

    &::after {
      content: attr(data-count);
      @apply border border-gray-500 py-0.5 px-2 rounded-3xl text-base relative text-center font-normal ml-2;
    }

    &.in-progress::after {
      @apply border-gray-700;
    }

    &.to-do::after,
    &.done::after {
      @apply border-white;
    }
    &.due,
    &.priority,
    &.assignees,
    &.client {
      @apply bg-gray-500;

      &::after {
        @apply border border-white;
      }
    }
    &.status,
    &.priority {
      &.in-progress,
      &.normal {
        background: #f9dc7d;
        @apply text-gray-700;
      }
      &.to-do,
      &.low {
        background: #d3e4f5;
        color: #002f5d;
      }
      &.done {
        background: #cfffdd;
        color: #00966d;
      }
      &.high {
        @apply text-white bg-red-400;
      }
      &.open {
        background: #ececec;
        color: #666666;
      }
      &.in-progress {
        background: #ffefd0;
        color: #bfa654;
      }
      &.for-initial-editing {
        background: #f2e1ff;
        color: #ae63d1;
      }
      &.for-final-editing {
        background: #ffd4f6;
        color: #e14dca;
      }
      &.revision {
        background: #ffafaf;
        color: #c30000;
      }
      &.complete {
        background: #cfffdd;
        color: #00966d;
      }
      &.submitted {
        background: #cfffdd;
        color: #13e9b0;
      }
      &.on-hold-or-cancel {
        background: #ffd2b9;
        color: #d48236;
      }
      &.incomplete {
        background: #ffe6e6;
        color: #d85b5b;
      }
    }
  }
  .list-group-th {
    @apply text-xs text-gray-400 self-center text-center;
  }

  .list-group-col {
    @apply sm:grid grid-cols-12 py-4;
    box-shadow: 0px 5px 10px 1px rgba(17, 17, 17, 0.06);
    -webkit-box-shadow: 0px 5px 10px 1px rgba(17, 17, 17, 0.06);
    -moz-box-shadow: 0px 5px 10px 1px rgba(17, 17, 17, 0.06);
  }

  .list-group-col-subs {
    @apply space-y-3 py-4;
    box-shadow: 0px 5px 10px 1px rgba(17, 17, 17, 0.06);
    -webkit-box-shadow: 0px 5px 10px 1px rgba(17, 17, 17, 0.06);
    -moz-box-shadow: 0px 5px 10px 1px rgba(17, 17, 17, 0.06);
  }

  .list-group-col-sub {
    @apply sm:grid grid-cols-12;
  }

  .drop-active {
    &.in-progress,
    &.normal {
      @apply border-yellow-100;
    }
    &.to-do,
    &.low {
      @apply border-blue-100;
    }
    &.done {
      @apply border-green-100;
    }
    &.high {
      @apply border-red-100;
    }
  }
}

.add-subtask-icon {
  &:hover {
    svg {
      path {
        fill: #ef4444;
      }
      rect {
        stroke: #ef4444;
      }
    }
  }
}

body.tasks {
  &.list {
    .list-container {
      @apply space-y-12;

      .list-group {
        .list-group-header {
          @apply sm:grid grid-cols-12 border-b border-gray-50;
        }
      }
    }
  }
}

.tasks-light {
  .list-group-col {
    box-shadow: 0px 0px 9px 2px rgba(17, 17, 17, 0.06);
    -webkit-box-shadow: 0px 0px 9px 2px rgba(17, 17, 17, 0.06);
    -moz-box-shadow: 0px 0px 9px 2px rgba(17, 17, 17, 0.06);
    @apply bg-white;
  }
  &.list {
    .list-group-col-subs {
      box-shadow: 0px 0px 9px 2px rgba(17, 17, 17, 0.06);
      -webkit-box-shadow: 0px 0px 9px 2px rgba(17, 17, 17, 0.06);
      -moz-box-shadow: 0px 0px 9px 2px rgba(17, 17, 17, 0.06);
      @apply bg-white;
    }
  }
}

.kanban {
  .list-container {
    display: flex;
    @apply space-x-20;

    .list-group {
      flex: 0 0 280px;
      @apply m-0;

      .list-group-header {
        @apply sm:flex mb-4 shadow-lg border-0;

        .kanban-col-span-12 {
          @apply col-span-12 w-full;
        }

        .list-group-th {
          @apply hidden;
        }

        .list-group-tab {
          @apply uppercase px-6 py-2 w-full;
        }
      }
      .list-group-col {
        box-shadow: 0px 0px 9px 2px rgba(17, 17, 17, 0.06);
        -webkit-box-shadow: 0px 0px 9px 2px rgba(17, 17, 17, 0.06);
        -moz-box-shadow: 0px 0px 9px 2px rgba(17, 17, 17, 0.06);
      }

      .list-group-col-subs {
        @apply border-t p-0 bg-gray-50;
      }
    }
  }
}
