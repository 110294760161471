.preview-page {
  p > img {
    display: inline;
  }

  ul {
    list-style-type: disc;
    padding-inline-start: 40px;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
  ol {
    list-style-type: decimal;
    padding-inline-start: 40px;
    margin-block-start: 1em;
    margin-block-end: 1em;
  }
}
