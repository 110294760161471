.tabnav {
  #tabs {
    @apply block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md;
  }
  nav {
    @apply flex;
  }

  &.default {
    .tab-container {
      @apply border-b border-gray-200;
    }
    nav {
      @apply space-x-8;
    }
    .tab-btn {
      @apply whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm  border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200;

      &.current {
        @apply border-red-500 text-red-600;
      }
    }
  }
  &.tabbed {
    .tab-btn {
      @apply bg-gray-50 text-gray-400 hover:text-secondary border-gray-200 border-b py-3 w-36 text-sm border-t border-l border-r rounded-t-md text-center leading-none relative;

      &.current {
        @apply font-semibold text-secondary-light bg-white border-white;
        &.middle::before,
        &.right::before {
          content: '';
          width: 10px;
          height: 10px;
          bottom: -5px;
          left: -10px;
          position: absolute;
          background: white;
          border-radius: 10px;
        }
        &.middle::after,
        &.left::before {
          content: '';
          width: 10px;
          height: 10px;
          bottom: -5px;
          right: -10px;
          position: absolute;
          background: white;
          border-radius: 10px;
        }
      }
      &.tail-left {
        @apply rounded-bl-md z-10;
      }
      &.tail-right {
        @apply rounded-br-md z-10;
      }
    }
  }
}
.item-page-tab-btn {
  z-index: 0 !important;
}
