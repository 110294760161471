.advertising-funnel {
  &::before {
    content: '';
    background: red;
  }
  .trapezoid {
    display: flex;
    padding: 1.5vh 2vw 2vh;
    position: relative;
    flex-direction: column;
    text-align: center;
    overflow-y: clip;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-top: 6em solid #fb3767;
      border-left: 2em solid transparent;
      border-right: 2em solid transparent;
    }

    // &::after {
    //   content: 'Impressions';
    //   position: absolute;
    //   color: #fb3767;
    //   left: -120px;
    // }

    span {
      position: relative;
    }

    &:nth-child(2) {
      margin: 0 26px;

      &::before {
        border-top: 6em solid #ff89a6;
      }

      // &::after {
      //   content: 'Clicked Through Rate';
      //   position: absolute;
      //   color: #ff89a6;
      //   left: -146px;
      // }
    }

    &:nth-child(3) {
      margin: 0 50px;

      &::before {
        border-top: 6em solid #002f5d;
      }

      // &::after {
      //   content: 'Clicks';
      //   position: absolute;
      //   color: #002f5d;
      //   left: -170px;
      // }
    }

    &:nth-child(4) {
      margin: 0 74px;

      &::before {
        border-top: 6em solid #1879d8;
      }

      // &::after {
      //   content: 'Conversion Rate';
      //   position: absolute;
      //   color: #1879d8;
      //   left: -194px;
      // }
    }

    &:nth-child(5) {
      margin: 0 100px;

      &::before {
        border-top: 6em solid #00ba88;
      }

      // &::after {
      //   content: 'Orders';
      //   position: absolute;
      //   color: #00ba88;
      //   left: -220px;
      // }
    }
  }
}
