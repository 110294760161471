@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,1,0');
@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./fonts/SourceSansPro-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@layer base {
  html {
    font-family: 'Source Sans Pro', system-ui, sans-serif;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Inter', system-ui, sans-serif;
  }
}

@layer utilities {
  .text-1sm {
    font-size: 13px;
  }
  .text-1xs {
    font-size: 11px;
  }
  .text-2xs {
    font-size: 10px;
  }
  .bg-gray-40 {
    background-color: #fcfcfc;
  }
  .bg-yellow-350 {
    background-color: #f9dc7d;
  }
  .z-1 {
    z-index: 1;
  }
  .z-2 {
    z-index: 2;
  }
  .z-3 {
    z-index: 3;
  }
  .w-800 {
    width: 800px;
  }
}

/* Applied on the checkbox for the table */
.selection-cell {
  @apply text-center;
}

.caret {
  @apply bg-gray-900;
}

input[type='number']#price::-webkit-inner-spin-button,
input[type='number']#price::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'].noscroll::-webkit-inner-spin-button,
input[type='number'].noscroll::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.szh-menu-container {
  width: 100% !important;
}

fieldset[disabled] input[type='checkbox'],
fieldset[disabled] input[type='radio'],
input[type='checkbox'].disabled,
input[type='checkbox'][disabled],
input[type='radio'].disabled,
input[type='radio'][disabled],
button[disabled] {
  cursor: not-allowed;
}

.text-ellipsis {
  text-overflow: ellipsis;
}

.agency-layout .main-content-inner1 {
  @apply py-6;
}
.agency-layout .main-content-inner2 {
  @apply px-4 sm:px-6 md:px-8;
}

body.client-profile .agency-layout .main-content-inner1 {
  @apply pt-6 pb-0;
}
body.client-profile .agency-layout .main-content-inner2 {
  @apply pl-4 sm:pl-6 md:pl-8 pr-0;
}

.form-input {
  @apply border-white bg-gray-50 focus:ring-gray-200 focus:border-gray-200 hover:border-gray-200 block w-full sm:text-sm placeholder-gray-300 rounded-xl py-2.5;
}
.form-input-w {
  @apply border-white bg-white focus:ring-gray-200 focus:border-gray-200 hover:border-gray-200 block w-full sm:text-sm placeholder-gray-300 rounded-xl py-2.5;
}
.form-input-g {
  @apply border-grayscale-500 bg-gray-50 focus:ring-gray-200 focus:border-gray-200 hover:border-gray-200 block w-full sm:text-sm placeholder-gray-300 rounded-xl py-2.5;
}

.form-select {
  @apply block w-full pl-3 pr-10 py-2.5 rounded-xl focus:outline-none sm:text-sm appearance-none border-white bg-gray-50 placeholder-gray-400 focus:ring-gray-200 focus:border-gray-200 hover:border-gray-200;
}
.disabled-gray {
  @apply disabled:bg-gray-200;
}
.disabled-white {
  @apply disabled:bg-white disabled:border-white;
}
.btn-red {
  @apply inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600  hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mt-2 mr-2;
}
.btn-green {
  @apply inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600  hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mt-2 mr-2;
}

.org-tree-container {
  background: transparent !important;
}
.org-tree-node-label .org-employees.org-tree-node-label-inner {
  padding: 5px;
}
.org-tree-node-label .org-tree-node-label-inner {
  border-radius: 6px;
}

.menu-popup-div {
  height: 95vh;
  top: 64px;
}
.menu-popup-div.menu {
  top: 48px;
}

.bell-div .arrow-top {
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom-width: 15px;
  border-bottom-color: #d1fae5;
  bottom: -18px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}

.bell-notif.opened .bell-div .arrow-top {
  opacity: 1;
}
.h-54vh {
  height: 54vh;
}
.h-87vh {
  height: 87vh;
}
.h-90vh {
  height: 90vh;
}

.h-80vh {
  height: 80vh;
}

.h-75vh {
  height: 75vh;
}

.h-70vh {
  height: 70vh;
}

.h-10vh {
  height: 10vh;
}

.h-50vh {
  height: 50vh;
}

.h-60vh {
  height: 60vh;
}

.min-h-77 {
  min-height: 77%;
}
.w-90vw {
  width: 90vw;
}
.w-88 {
  width: 22rem;
}
.max-h-54vh {
  max-height: 54vh;
}
.max-h-60vh {
  max-height: 60vh;
}
.container-fixed {
  height: auto;
  overflow-y: auto;
}
@screen lg {
  .container-fixed {
    height: 72vh;
  }
  .agency-client .container-fixed {
    height: 66.5vh;
  }
  .invoice-page .container-fixed {
    height: 74vh;
  }
}

@screen 2xl {
  .container-fixed {
    height: 77vh;
  }
  .agency-client .container-fixed {
    height: 71.5vh;
  }
  .invoice-page .container-fixed {
    height: 79vh;
  }
}

.tablinks > a {
  @apply font-normal text-gray-400 bg-gray-50 border-gray-200 border-b relative;
}

.tablinks > a.current {
  @apply font-semibold text-red-500 bg-white border-white;
}

.tablinks > a.current__secondary {
  @apply font-semibold text-secondary-light bg-white border-white;
}

.tablinks > a.tail-left {
  @apply rounded-bl-md z-10;
}
.tablinks > a.tail-right {
  @apply rounded-br-md z-10;
}

.tablinks > a.current.middle::before,
.tablinks > a.current.right::before {
  content: '';
  width: 10px;
  height: 10px;
  bottom: -5px;
  left: -10px;
  position: absolute;
  background: white;
  border-radius: 10px;
}
.tablinks > a.current.middle::after,
.tablinks > a.current.left::before {
  content: '';
  width: 10px;
  height: 10px;
  bottom: -5px;
  right: -10px;
  position: absolute;
  background: white;
  border-radius: 10px;
}

.tablinks > a.disabled {
  background: #eee !important;
  pointer-events: none;
}

.curve-fix::after {
  content: '';
  position: absolute;
  width: 75%;
  background: white;
  height: 3.5px;
}
