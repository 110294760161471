.react-time-picker {
  display: inline-flex !important;
  position: relative !important;
}

.react-time-picker,
.react-time-picker *,
.react-time-picker *:before,
.react-time-picker *:after {
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

.react-time-picker--disabled {
  background-color: #f0f0f0 !important;
  color: #6d6d6d !important;
}

.react-time-picker__wrapper {
  display: flex !important;
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  border: thin solid gray !important;
}

.react-time-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 6 + 0.217em * 2) !important;
  flex-grow: 1 !important;
  padding: 0 2px !important;
  box-sizing: content-box !important;
}

.react-time-picker__inputGroup__divider {
  padding: 1px 0 !important;
  white-space: pre !important;
}

.react-time-picker__inputGroup__divider,
.react-time-picker__inputGroup__leadingZero {
  display: inline-block !important;
}

.react-time-picker__inputGroup__input {
  min-width: 0.54em !important;
  height: 100% !important;
  position: relative !important;
  padding: 0 1px !important;
  border: 0 !important;
  background: none !important;
  color: currentColor !important;
  font: inherit !important;
  box-sizing: content-box !important;
  -webkit-appearance: textfield !important;
  -moz-appearance: textfield !important;
  appearance: textfield !important;
}

.react-time-picker__inputGroup__input::-webkit-outer-spin-button,
.react-time-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  margin: 0 !important;
}

.react-time-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1) !important;
}

.react-time-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em !important;
  padding-left: calc(1px + 0.54em) !important;
}

.react-time-picker__inputGroup__amPm {
  font: inherit !important;
  -webkit-appearance: menulist !important;
  -moz-appearance: menulist !important;
  appearance: menulist !important;
}

.react-time-picker__button {
  border: 0 !important;
  background: transparent !important;
  padding: 4px 6px !important;
}

.react-time-picker__button:enabled {
  cursor: pointer !important;
}

.react-time-picker__button:enabled:hover .react-time-picker__button__icon,
.react-time-picker__button:enabled:focus .react-time-picker__button__icon {
  stroke: #0078d7 !important;
}

.react-time-picker__button:disabled .react-time-picker__button__icon {
  stroke: #6d6d6d !important;
}

.react-time-picker__button svg {
  display: inherit !important;
}

.react-time-picker__clock {
  width: 200px !important;
  height: 200px !important;
  max-width: 100vw !important;
  padding: 25px !important;
  background-color: white !important;
  border: thin solid #a0a096 !important;
  z-index: 1 !important;
}

.react-time-picker__clock--closed {
  display: none !important;
}
