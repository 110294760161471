#privacy-policy {
  .lst-kix_7ufcggtegida-5 > li:before {
    content: '\0025a0   ';
  }

  .lst-kix_7ufcggtegida-4 > li:before {
    content: '\0025cb   ';
  }

  .lst-kix_7ufcggtegida-6 > li:before {
    content: '\0025cf   ';
  }

  .lst-kix_7ufcggtegida-3 > li:before {
    content: '\0025cf   ';
  }

  .lst-kix_7ufcggtegida-7 > li:before {
    content: '\0025cb   ';
  }

  .lst-kix_7ufcggtegida-1 > li:before {
    content: '\0025cb   ';
  }

  .lst-kix_7ufcggtegida-0 > li:before {
    content: '\0025cf   ';
  }

  .lst-kix_7ufcggtegida-2 > li:before {
    content: '\0025a0   ';
  }

  .lst-kix_7ufcggtegida-8 > li:before {
    content: '\0025a0   ';
  }

  .lst-kix_3syc9youvmm7-0 > li:before {
    content: '\0025cf   ';
  }

  .lst-kix_3syc9youvmm7-1 > li:before {
    content: '\0025cb   ';
  }

  .lst-kix_3syc9youvmm7-2 > li:before {
    content: '\0025a0   ';
  }

  .lst-kix_3syc9youvmm7-3 > li:before {
    content: '\0025cf   ';
  }

  ul.lst-kix_nzc3cpnjlle7-3 {
    list-style-type: none;
  }

  ul.lst-kix_nzc3cpnjlle7-2 {
    list-style-type: none;
  }

  .lst-kix_3syc9youvmm7-6 > li:before {
    content: '\0025cf   ';
  }

  .lst-kix_3syc9youvmm7-7 > li:before {
    content: '\0025cb   ';
  }

  ul.lst-kix_nzc3cpnjlle7-5 {
    list-style-type: none;
  }

  ul.lst-kix_nzc3cpnjlle7-4 {
    list-style-type: none;
  }

  ul.lst-kix_nzc3cpnjlle7-7 {
    list-style-type: none;
  }

  .lst-kix_3syc9youvmm7-8 > li:before {
    content: '\0025a0   ';
  }

  ul.lst-kix_nzc3cpnjlle7-6 {
    list-style-type: none;
  }

  .lst-kix_3syc9youvmm7-4 > li:before {
    content: '\0025cb   ';
  }

  .lst-kix_3syc9youvmm7-5 > li:before {
    content: '\0025a0   ';
  }

  ul.lst-kix_nzc3cpnjlle7-8 {
    list-style-type: none;
  }

  ul.lst-kix_wcf48cj998y5-3 {
    list-style-type: none;
  }

  ul.lst-kix_wcf48cj998y5-2 {
    list-style-type: none;
  }

  ul.lst-kix_wcf48cj998y5-5 {
    list-style-type: none;
  }

  ul.lst-kix_wcf48cj998y5-4 {
    list-style-type: none;
  }

  ul.lst-kix_wcf48cj998y5-7 {
    list-style-type: none;
  }

  ul.lst-kix_wcf48cj998y5-6 {
    list-style-type: none;
  }

  ul.lst-kix_nzc3cpnjlle7-1 {
    list-style-type: none;
  }

  ul.lst-kix_wcf48cj998y5-8 {
    list-style-type: none;
  }

  ul.lst-kix_nzc3cpnjlle7-0 {
    list-style-type: none;
  }

  ul.lst-kix_wcf48cj998y5-1 {
    list-style-type: none;
  }

  ul.lst-kix_wcf48cj998y5-0 {
    list-style-type: none;
  }

  .lst-kix_nzc3cpnjlle7-0 > li:before {
    content: '\0025cf   ';
  }

  .lst-kix_wcf48cj998y5-0 > li:before {
    content: '\0025cf   ';
  }

  .lst-kix_nzc3cpnjlle7-4 > li:before {
    content: '\0025cb   ';
  }

  .lst-kix_nzc3cpnjlle7-5 > li:before {
    content: '\0025a0   ';
  }

  .lst-kix_wcf48cj998y5-1 > li:before {
    content: '\0025cb   ';
  }

  .lst-kix_wcf48cj998y5-2 > li:before {
    content: '\0025a0   ';
  }

  .lst-kix_nzc3cpnjlle7-1 > li:before {
    content: '\0025cb   ';
  }

  .lst-kix_wcf48cj998y5-3 > li:before {
    content: '\0025cf   ';
  }

  .lst-kix_wcf48cj998y5-5 > li:before {
    content: '\0025a0   ';
  }

  .lst-kix_nzc3cpnjlle7-2 > li:before {
    content: '\0025a0   ';
  }

  .lst-kix_nzc3cpnjlle7-3 > li:before {
    content: '\0025cf   ';
  }

  .lst-kix_wcf48cj998y5-4 > li:before {
    content: '\0025cb   ';
  }

  .lst-kix_wcf48cj998y5-7 > li:before {
    content: '\0025cb   ';
  }

  .lst-kix_wcf48cj998y5-6 > li:before {
    content: '\0025cf   ';
  }

  .lst-kix_nzc3cpnjlle7-8 > li:before {
    content: '\0025a0   ';
  }

  .lst-kix_nzc3cpnjlle7-6 > li:before {
    content: '\0025cf   ';
  }

  .lst-kix_nzc3cpnjlle7-7 > li:before {
    content: '\0025cb   ';
  }

  .lst-kix_wcf48cj998y5-8 > li:before {
    content: '\0025a0   ';
  }

  ul.lst-kix_x852ll5sc215-8 {
    list-style-type: none;
  }

  ul.lst-kix_x852ll5sc215-7 {
    list-style-type: none;
  }

  ul.lst-kix_x852ll5sc215-6 {
    list-style-type: none;
  }

  ul.lst-kix_x852ll5sc215-5 {
    list-style-type: none;
  }

  ul.lst-kix_x852ll5sc215-4 {
    list-style-type: none;
  }

  ul.lst-kix_x852ll5sc215-3 {
    list-style-type: none;
  }

  ul.lst-kix_x852ll5sc215-2 {
    list-style-type: none;
  }

  ul.lst-kix_x852ll5sc215-1 {
    list-style-type: none;
  }

  ul.lst-kix_x852ll5sc215-0 {
    list-style-type: none;
  }

  .lst-kix_x852ll5sc215-1 > li:before {
    content: '\0025cb   ';
  }

  .lst-kix_x852ll5sc215-0 > li:before {
    content: '\0025cf   ';
  }

  ul.lst-kix_7ufcggtegida-4 {
    list-style-type: none;
  }

  .lst-kix_8mrsb9o31xj7-8 > li:before {
    content: '\0025a0   ';
  }

  ul.lst-kix_7ufcggtegida-5 {
    list-style-type: none;
  }

  ul.lst-kix_7ufcggtegida-2 {
    list-style-type: none;
  }

  ul.lst-kix_7ufcggtegida-3 {
    list-style-type: none;
  }

  ul.lst-kix_7ufcggtegida-0 {
    list-style-type: none;
  }

  ul.lst-kix_7ufcggtegida-1 {
    list-style-type: none;
  }

  .lst-kix_8mrsb9o31xj7-7 > li:before {
    content: '\0025cb   ';
  }

  .lst-kix_8mrsb9o31xj7-4 > li:before {
    content: '\0025cb   ';
  }

  .lst-kix_8mrsb9o31xj7-6 > li:before {
    content: '\0025cf   ';
  }

  .lst-kix_8mrsb9o31xj7-5 > li:before {
    content: '\0025a0   ';
  }

  .lst-kix_8mrsb9o31xj7-0 > li:before {
    content: '\0025cf   ';
  }

  .lst-kix_8mrsb9o31xj7-3 > li:before {
    content: '\0025cf   ';
  }

  ul.lst-kix_7ufcggtegida-8 {
    list-style-type: none;
  }

  .lst-kix_8mrsb9o31xj7-2 > li:before {
    content: '\0025a0   ';
  }

  ul.lst-kix_7ufcggtegida-6 {
    list-style-type: none;
  }

  .lst-kix_8mrsb9o31xj7-1 > li:before {
    content: '\0025cb   ';
  }

  ul.lst-kix_7ufcggtegida-7 {
    list-style-type: none;
  }

  ul.lst-kix_8mrsb9o31xj7-5 {
    list-style-type: none;
  }

  ul.lst-kix_3syc9youvmm7-0 {
    list-style-type: none;
  }

  ul.lst-kix_8mrsb9o31xj7-4 {
    list-style-type: none;
  }

  ul.lst-kix_3syc9youvmm7-1 {
    list-style-type: none;
  }

  ul.lst-kix_8mrsb9o31xj7-7 {
    list-style-type: none;
  }

  ul.lst-kix_3syc9youvmm7-2 {
    list-style-type: none;
  }

  ul.lst-kix_8mrsb9o31xj7-6 {
    list-style-type: none;
  }

  ul.lst-kix_3syc9youvmm7-3 {
    list-style-type: none;
  }

  .lst-kix_x852ll5sc215-2 > li:before {
    content: '\0025a0   ';
  }

  ul.lst-kix_3syc9youvmm7-4 {
    list-style-type: none;
  }

  ul.lst-kix_8mrsb9o31xj7-8 {
    list-style-type: none;
  }

  ul.lst-kix_3syc9youvmm7-5 {
    list-style-type: none;
  }

  ul.lst-kix_3syc9youvmm7-6 {
    list-style-type: none;
  }

  ul.lst-kix_3syc9youvmm7-7 {
    list-style-type: none;
  }

  .lst-kix_x852ll5sc215-3 > li:before {
    content: '\0025cf   ';
  }

  ul.lst-kix_3syc9youvmm7-8 {
    list-style-type: none;
  }

  .lst-kix_x852ll5sc215-4 > li:before {
    content: '\0025cb   ';
  }

  .lst-kix_x852ll5sc215-6 > li:before {
    content: '\0025cf   ';
  }

  ul.lst-kix_8mrsb9o31xj7-1 {
    list-style-type: none;
  }

  ul.lst-kix_8mrsb9o31xj7-0 {
    list-style-type: none;
  }

  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }

  ul.lst-kix_8mrsb9o31xj7-3 {
    list-style-type: none;
  }

  ul.lst-kix_8mrsb9o31xj7-2 {
    list-style-type: none;
  }

  .lst-kix_x852ll5sc215-5 > li:before {
    content: '\0025a0   ';
  }

  .lst-kix_x852ll5sc215-8 > li:before {
    content: '\0025a0   ';
  }

  .lst-kix_x852ll5sc215-7 > li:before {
    content: '\0025cb   ';
  }

  ol {
    margin: 0;
    padding: 0;
  }

  table td,
  table th {
    padding: 0;
  }

  .c0 {
    margin-left: 36pt;
    padding-top: 12pt;
    padding-left: 0pt;
    padding-bottom: 12pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .eula {
    .c0 {
      padding-bottom: 0;
    }
  }

  .c16 {
    background-color: #ffffff;
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c17 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }

  .c6 {
    background-color: #ffffff;
    padding-top: 18pt;
    padding-bottom: 4pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c13 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 24pt;
    font-family: 'Arial';
    font-style: normal;
  }

  .c5 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 15pt;
    font-family: 'Arial';
    font-style: normal;
  }

  .c10 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 30pt;
    font-family: 'Arial';
    font-style: normal;
  }

  .c3 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: 'Arial';
    font-style: normal;
  }

  .c2 {
    background-color: #ffffff;
    padding-top: 14pt;
    padding-bottom: 4pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c4 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'Arial';
    font-style: normal;
  }

  .c14 {
    background-color: #ffffff;
    padding-top: 12pt;
    padding-bottom: 2pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c15 {
    background-color: #ffffff;
    padding-top: 24pt;
    padding-bottom: 6pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c8 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 18pt;
    font-family: 'Arial';
    font-style: normal;
  }

  .c7 {
    background-color: #ffffff;
    padding-top: 12pt;
    padding-bottom: 12pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c9 {
    text-decoration-skip-ink: none;
    font-size: 12pt;
    -webkit-text-decoration-skip: none;
    color: #0452a5;
    text-decoration: underline;
  }

  .c12 {
    font-weight: 400;
    vertical-align: baseline;
    font-family: 'Arial';
    font-style: normal;
  }

  .c20 {
    background-color: #ffffff;
    /* max-width: 468pt; */
    padding: 72pt 72pt 72pt 72pt;
  }

  .c1 {
    font-size: 12pt;
    font-weight: 700;
  }

  .eula {
    .c1 {
      font-weight: 400;
    }
  }

  .c11 {
    padding: 0;
    margin: 0;
  }

  .c18 {
    color: inherit;
    text-decoration: inherit;
  }

  .c19 {
    font-size: 12pt;
  }

  .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  li {
    color: #000000;
    font-size: 11pt;
    font-family: 'Arial';
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: 'Arial';
  }

  h1 {
    padding-top: 20pt;
    color: #000000;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h2 {
    padding-top: 18pt;
    color: #000000;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: 'Arial';
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
