.before-arrow {
  position: relative;
  &::after {
    position: absolute;
    right: -10px;
  }

  &.is-selected {
    &::after {
      content: '\2023';
      right: -15px;
      font-size: 18px;
    }
  }

  &.mobile {
    &::after {
      content: '\203A';
      border-left-color: #fff;
    }
    &.expand {
      &::after {
        content: '\203A';
        transform: rotate(90deg);
      }
    }
  }
}

.selected-menu {
  &::after {
    content: '';
    position: absolute;
    right: -10px;
    top: 0;
    height: 100%;
    background-color: white;
    width: 15px;
  }
}

.select-arrow {
  .arrow-left {
    @apply absolute left-0 border-l border-red-500;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left-width: 10px;
  }
}
