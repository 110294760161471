.dayzed-calendar {
  @apply text-gray-500;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;

  .nav {
    text-align: right;
    display: flex;
    align-items: center;

    .back,
    .next {
      @apply bg-gray-100 text-gray-500 rounded-sm hover:text-gray-700 hover:bg-gray-200;
    }
  }

  .calendar {
    display: inline-block;
    width: 100%;
    padding: 0 10px 0px;
    box-sizing: border-box;

    .current-month {
      @apply pl-2 text-gray-700;
      text-align: left;
    }

    .calendar-cell {
      width: calc(100% / 7);
    }
  }
}
