.trapezoid1 {
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%);
  background-color: #fb3767;
  width: 100%;
  // height:60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.trapezoid2 {
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%);
  background-color: #ff89a6;
  width: 80%;
  // height:60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.trapezoid3 {
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%);
  background-color: #002f5d;
  width: 63%;
  // height:60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.trapezoid4 {
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%);
  background-color: #1879d8;
  width: 50%;
  // height:60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.trapezoid5 {
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%);
  background-color: #00ba88;
  width: 40%;
  // height:60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
