.react-calendar__tile {
  border-radius: 50%;
  line-height: 25px;
}

.react-calendar__tile--hover {
  background: rgb(59 130 246);
}

.react-calendar__tile--now,
.react-calendar__tile--now:hover {
  background: #002f5d;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: rgb(147 197 253);
}

.react-calendar__month-view__days__day {
  background: #d3e4f5;
}

.react-calendar__month-view__days__day--weekend {
  color: #000;
  background: #fff;
}

.react-calendar__tile--active {
  background: #002f5d;
}

.react-calendar {
  border: 0px;
}
.react-calendar__month-view__weekdays {
  color: #bebebe;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.scroll-bar::-webkit-scrollbar {
  display: none;
}
