.quill-mailblock-editor {
  .quill {
    @apply h-full;
  }
  .ql-toolbar.ql-snow {
    @apply border-none;
  }
  .ql-container.ql-snow {
    @apply border-none;
    min-height: 10em;

    &.ql-bubble {
      .ql-tooltip {
        border-radius: 5px;
      }
    }
  }
  &.edit-comment .ql-container {
    min-height: auto;
  }

  .ql-toolbar {
    button {
      .ql-stroke,
      .ql-fill,
      .ql-picker {
        stroke: #6b7280;
      }

      &.ql-active {
        .ql-stroke,
        .ql-fill,
        .ql-picker {
          stroke: #ef4444;
        }
      }

      &:hover {
        color: #ef4444;

        .ql-stroke,
        .ql-fill,
        .ql-picker {
          stroke: #ef4444;
        }
      }
    }
  }
  .ql-editor {
    @apply focus:bg-gray-100 bg-white hover:bg-gray-100 focus:border-black hover:border-gray-500 block w-full sm:text-sm placeholder-gray-300 rounded-xl;
    max-height: 38vh;
    overflow-y: scroll;
  }
}

.raw-editor {
  height: 38vh;
  max-height: 38vh;
  overflow-y: scroll;
}

.show-raw {
  .quill-mailblock-editor {
    display: none;
  }
}
